import React from "react";

function Wrapper(props) {
  return (
    <section className={` ${props.className}  w-[94%] m-auto md:w-[88%]`}>
      {props.children}
    </section>
  );
}

export const WrapperDiv = (props) => {
  return (
    <div className={` ${props.className}  w-[94%] m-auto md:w-[88%]`}>
      {props.children}
    </div>
  );
};

export default Wrapper;
