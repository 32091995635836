import { useState, useRef, useEffect } from "react";

function StyledTextTemplate(props) {
  // const targetRef = useRef();

  // const [dontShowAgain, setDontShowAgain] = useState("");

  // const options = {
  //   threshold: 0.01,
  // };

  // const [isVisible, setIsvisible] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(([entry]) => {
  //     setIsvisible(entry.isIntersecting);
  //   }, options);

  //   if (targetRef.current) {
  //     observer.observe(targetRef.current);
  //   }

  //   return () => {
  //     if (targetRef.current) {
  //       observer.unobserve(targetRef.current);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isVisible === true) {
  //     setDontShowAgain(props.type);
  //   }
  // }, [isVisible]);

  if (props.text === "a") {
    return (
      <a
        className={`
  
    
     ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </a>
    );
  }

  // if (props.text === "div") {
  //   return (
  //     <div
  //       ref={targetRef}
  //       className={`
  // ${isVisible ? `${props.type}` : "opacity-0"}

  //    ${props.classes} ${dontShowAgain} `}
  //       href={props.href}
  //     >
  //       {props.children}
  //     </div>
  //   );
  // }

  if (props.text === "div") {
    return (
      <div
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </div>
    );
  }

  if (props.text === "p") {
    return (
      <p
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </p>
    );
  }

  if (props.text === "h1") {
    return (
      <h1
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </h1>
    );
  }

  if (props.text === "h2") {
    return (
      <h2
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </h2>
    );
  }

  if (props.text === "h3") {
    return (
      <h3
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </h3>
    );
  }

  if (props.text === "ul") {
    return (
      <ul
        className={`
  
    
      ${props.classes} `}
        href={props.href}
      >
        {props.children}
      </ul>
    );
  }
}

export default StyledTextTemplate;
