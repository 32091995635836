import React, { useEffect } from "react";

import { useAllContext } from "./AllContext";

const HamburgerButton = () => {
  const {
    isHamburgerButtonPressed: isHamburgerButtonPressed,
    toggleHamburgerButton: toggleHamburgerButton,
  } = useAllContext();

  return (
    <div className="relative lg:hidden">
      <svg
        viewBox="25 25 50 50"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-[3rem] w-[3rem] cursor-pointer `}
        onClick={toggleHamburgerButton}
      >
        <path
          className={`path line--1 ${
            isHamburgerButtonPressed ? "activate" : ""
          } `}
          d="M0 40h62c13 0 6 28-4 18L35 35"
        />
        <path
          className={` path  line--2  ${
            isHamburgerButtonPressed ? "activate" : ""
          }`}
          d="M0 50h70"
        />
        <path
          className={` path  line--3  ${
            isHamburgerButtonPressed ? "activate" : ""
          }`}
          d="M0 60h62c13 0 6-28-4-18L35 65"
        />
      </svg>
    </div>
  );
};

export default HamburgerButton;
