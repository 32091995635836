import "./index.css";
import Main from "./pages/Main";
import UiUx from "./pages/UiUx";
import QA from "./pages/QA";

import { Route, Routes } from "react-router-dom";
import { AllContextProvider } from "./components/UI/AllContext";

export default function App() {
  return (
    <AllContextProvider>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Ui-Ux" element={<UiUx />} />

        <Route path="/QA" element={<QA />} />
      </Routes>
    </AllContextProvider>
  );
}
