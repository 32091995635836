import React from "react";
import AboutUs from "./UI/AboutUs";
import ImageSlider from "./UI/ImageSlider";
import WhyChooseUs from "./UI/WhyChooseUs";

const MainContent = () => {
  return (
    <>
      <AboutUs />
      <ImageSlider />
      <WhyChooseUs />
    </>
  );
};

export default MainContent;
