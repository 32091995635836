import React from "react";
import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";
import email from "../../images/e-mail-icon.svg";
import facebook from "../../images/fb.svg";
import beahnce from "../../images/beahnce.svg";
import linkedin from "../../images/linkedin.svg";
import Wrapper from "./Wrapper";

function Bottom(props) {
  const links = props.navBarLinks;

  return (
    <footer className="bg-neutral-900 ">
      <Wrapper
        className={
          "bg-neutral-900 flex flex-col items-center space-y-14 pb-10 lg:flex-row lg:gap-[8%] xl:gap-[13%] lg:items-start lg:pt-[6rem] pt-"
        }
      >
        <div className="flex justify-center items-center mt-[1.35rem] space-x-4 lg:flex-col lg:space-y-1 lg:items-start lg:justify-start lg:space-x-0">
          <Link
            to="/"
            className="text-fuchsia-600 text-[38px] leading-[46px] font-gilroyBold"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <img src={logo} className=" h-[2rem] w-[4rem] " alt="" />
          </Link>
          <h3 className=" text-natural-950 text-sm font-gilroy tracking-wide hidden lg:block ">
            Digital Business Development
          </h3>
        </div>

        <div className="flex flex-col space-y-4">
          <p className="font-gilroySemibold text-natural-950 text-xl pb-2 lg:text-left text-center">
            Contacts
          </p>

          <div className="flex justify-center items-center space-x-4 lg:ml-[-0.1rem]">
            <img src={email} alt="" />
            <p className="font-gilroySemibold text-natural-950 text-lg ">
              info@dbd.am
            </p>
          </div>

          <div className="flex items-center space-x-4 justify-center lg:justify-start ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/dbd-digital-business-development/"
            >
              <img src={linkedin} alt="" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/profile.php?id=100090663050971"
            >
              {" "}
              <img src={facebook} alt="" />
            </a>
          </div>
        </div>

        <div className="flex flex-col space-y-1">
          <p className="font-gilroySemibold text-natural-950 text-xl lg:text-left text-center pb-5">
            Services
          </p>

          <Link
            className=" hover:text-fuchsia-600 font-gilroySemibold text-natural-950 text-lg lg:text-left text-center "
            to="/Ui-Ux"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            UI/UX
          </Link>

          <Link
            className=" hover:text-fuchsia-600 font-gilroySemibold text-natural-950 text-lg lg:text-left text-center "
            to="/QA"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            QA Services
          </Link>
        </div>

        <div className="flex flex-col space-y-1">
          <p className="font-gilroySemibold text-natural-950 text-xl lg:text-left text-center pb-5 w-min-[6rem]">
            Quick Links
          </p>

          {links?.map((link, index) => {
            if (link.isContacts === true) {
              return;
            } else {
              return (
                <a
                  className=" font-gilroySemibold  w-min-[6rem] hover:text-fuchsia-600 text-natural-950 text-lg lg:text-left text-center"
                  href={link.href}
                  key={index}
                >
                  {link.name}
                </a>
              );
            }
          })}
        </div>
      </Wrapper>

      <p className="font-gilroy bg-neutral-900 text-natural-950 text-center pb-10 text-[14px] leading-[16px]">
        © 2022 DBD. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Bottom;
