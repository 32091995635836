import React from "react";

import HumburgerButton from "./UI/HamburgerButton";
import NavBar from "./UI/NavBar";
import { useState, useContext, useEffect } from "react";
import logo from "../images/logo.svg";

import { useAllContext } from "./UI/AllContext";

import { Link } from "react-router-dom";

import { WrapperDiv } from "./UI/Wrapper";

function Header(props) {
  const navBarLinks = props.navBarLinks;
  const {
    isHamburgerButtonPressed: isHamburgerButtonPressed,
    closeHamburgerButton: closeHamburgerButton,

    setIsScrolledEnoughDown: setIsScrolledEnoughDown,
    setIsScrolledEnoughUp: setIsScrolledEnoughUp,
  } = useAllContext();

  const [isArrowActive, setIsArrowActive] = useState(false);

  const [lastScrollTop, setlastScrollTop] = useState(false);
  const [howMuchIsItUp, setHowMuchIsItUp] = useState(0);
  const [isItFarEnoughUp, setIsItFarEnoughUp] = useState(false);

  const [scrollY, setScrollY] = useState(0);

  const listenScrollY = () => {
    setScrollY(window.pageYOffset * 0.005);

    if (window.pageYOffset < lastScrollTop) {
      if (howMuchIsItUp > window.pageYOffset + 170) {
        setIsItFarEnoughUp(true);
      }
    } else {
      setIsItFarEnoughUp(false);
      setHowMuchIsItUp(window.pageYOffset);
    }

    setIsScrolledEnoughUp(isItFarEnoughUp);

    setlastScrollTop(window.pageYOffset);

    if (window.pageYOffset > 1100) {
      setIsArrowActive(true);
    } else {
      setIsArrowActive(false);
    }

    setIsScrolledEnoughDown(isArrowActive);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", listenScrollY);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", listenScrollY);
    };
  });

  return (
    <section
      className={`${props.bgImgCSS} md:bg-cover md:h-screen md:bg-fixed md:bg-center md:flex md:flex-col md:justify-between`}
    >
      <div className="figure">
        <header
          className={
            isHamburgerButtonPressed
              ? "navbar-transition navbar  bg-neutral-900 z-50  fixed top-0 left-0 right-0"
              : `navbar-transition navbar  z-50  fixed top-0 left-0 right-0  `
          }
          style={{
            backgroundColor: isHamburgerButtonPressed
              ? `rgba(23, 23, 23)`
              : `rgba(23, 23, 23, ${scrollY})`,
          }}
        >
          <WrapperDiv
            className={
              " flex justify-between items-center  md:py-[0.3rem] py-[0.2rem]"
            }
          >
            <div>
              <Link
                to="/"
                className="text-fuchsia-600 text-[38px] leading-[46px] font-gilroyBold"
                onClick={() => {
                  closeHamburgerButton();
                  window.scroll(0, 0);
                }}
              >
                <img src={logo} className="h-[3rem] w-[5rem]" alt="" />
              </Link>
              <h2 className="text-natural-950 text-sm font-gilroy tracking-wide w-auto hidden lg:block">
                Digital Business Development
              </h2>
            </div>

            <NavBar navBarLinks={navBarLinks} />

            <HumburgerButton />
          </WrapperDiv>
        </header>
        <img className="myImg h-[90vh]  md:hidden" src={props.bgImg} alt="" />
        {props.text}
      </div>
      <div className="mb-[5rem]" id={navBarLinks[0].link}></div>
    </section>
  );
}

export default Header;
