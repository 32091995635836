import React from "react";
import "../index.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

import MainContent from "../components/MainContent";
import Wrapper from "../components/UI/Wrapper";

import ArrowUp from "../components/UI/ArrowUp";
import { HeaderMainText } from "../components/HeaderText";

import { Helmet } from "react-helmet";
import backgroundImage from "../images/1st-screen-img.jpg";

export const navBarLinks = [
  { isFirst: true, href: "#aboutus", name: "About Us", link: "aboutus" },
  { href: "#whychooseus", name: "Why choose us" },
  { isContacts: true, href: "#contacts", name: "Contacts" },
];

const bgImg = "background-main";
const text = <HeaderMainText />;

function Main() {
  return (
    <>
      <Helmet>
        <title>
          Top-Rated IT Services - QA, UX/UI - Digital Business Development
        </title>

        <meta
          name="description"
          content="Boost your business with our software development and design solutions. Expertise in QA automation, UI/UX design, and more. Contact us now."
        ></meta>

        <meta name="site_name" content="Digital Business Development" />

        <meta property="og:site_name" content="Digital Business Development" />
        <meta
          property="og:title"
          content="Top-Rated IT Services - QA, UX/UI - Digital Business Development"
        />
        <meta
          property="og:description"
          content="Boost your business with our software development and design solutions. Expertise in QA automation, UI/UX design, and more. Contact us now."
        />
        <meta property="og:url" content="https://dbd.am/" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/web-mobile-design_caheav.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/web-mobile-design_caheav.jpg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
      </Helmet>
      <ArrowUp />
      <Header
        bgImg={backgroundImage}
        bgImgCSS={bgImg}
        text={text}
        navBarLinks={navBarLinks}
      />

      <MainContent />
      <Footer navBarLinks={navBarLinks} />
    </>
  );
}

export default Main;
