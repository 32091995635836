import React from "react";
import QAIcon1 from "./images/qaicon1.svg";
import QAIcon2 from "./images/qaicon2.svg";
import QAIcon3 from "./images/qaicon3.svg";
import QAIcon4 from "./images/qaicon4.svg";
import QAIcon5 from "./images/qaicon5.svg";
import QAIcon6 from "./images/qaicon6.svg";
import QAChartBig from "./images/diagram-big.svg";
import QAChartSmall from "./images/diagram-small.svg";
import StyledTextTemplate from "./UI/StyledTextTemplate";
import Wrapper from "./UI/Wrapper";

function QAContent() {
  return (
    <>
      <Wrapper>
        <StyledTextTemplate
          classes="text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-12 font-gilroyBold tracking-wide  md:text-[32px] md:leading-[39px] "
          text="h2"
          type="text-header"
        >
          The Benefits of QA
        </StyledTextTemplate>
        <ul className=" pb-5 flex flex-col items-center xl:grid xl:grid-cols-3 md:gap-20 xl:justify-start uxdisplay space-y-20 sm:space-y-0 ">
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={QAIcon1} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Reduce costs
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Increase the volume and frequency of testing cycles, and start
              testing earlier with a “shift-left” approach that lowers the cost
              of fixing defects.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={QAIcon2} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Improve quality
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Automation enables you to mitigate risks related to human errors,
              build a baseline of quality, and establish measurement and control
              mechanisms.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={QAIcon3} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Reduce risk
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Transparent and measurable outcomes help to identify, manage and
              control risks related to testing.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start">
            <div>
              <img src={QAIcon4} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Improve speed and agility
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Higher velocity provides a competitive advantage and enables the
              reallocation of cost savings to other IT and business initiatives.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={QAIcon5} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Business-driven test focus
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Test automation increases the velocity of delivery teams by
              reducing the time it takes to ensure quality.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={QAIcon6} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Headcount optimization
            </h3>

            <p
              id="approach"
              className="text-natural-950 font-gilroy text-base md:text-lg text-center "
            >
              Test automation lowers the cost of repeatable testing cycles
              which, in general, consume most of the QA team’s capacity.
            </p>
          </li>
        </ul>
      </Wrapper>
      <Wrapper>
        <StyledTextTemplate
          classes="text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-12 font-gilroyBold tracking-wide   md:text-[32px] md:leading-[39px] "
          text="h2"
          type="text-header"
        >
          Our Approach
        </StyledTextTemplate>
        <div>
          <img src={QAChartSmall} className="h-full w-full md:hidden" alt="" />
          <img src={QAChartBig} className="h-full w-full qabig" alt="" />
        </div>
      </Wrapper>

      <Wrapper className=" overflow-auto mb-[5rem] "></Wrapper>
      <Wrapper className=" flex flex-col space-y-6  md:grid md:grid-cols-2 md:gap-14 md:space-y-0  ">
        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            01 - QA automation is the main driver for delivery acceleration
          </h3>
          <StyledTextTemplate
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base "
            text="p"
            type="text-from-above"
          >
            Having test automation ensures that development teams can deliver
            more products with higher quality by establishing reliable,
            automated and efficient testing procedures. This approach lowers
            both the costs and risks of software development projects.
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            02 - Flexible toolset options
          </h3>
          <StyledTextTemplate
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base "
            text="p"
            type="text-from-above"
          >
            We provide appropriate solutions based on technology and client
            requirements. We offer industry-leading vendor solutions, as well as
            flexible and popular open-source options.
          </StyledTextTemplate>
        </div>
        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            03 - Our domain expertise
          </h3>
          <StyledTextTemplate
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base "
            text="p"
            type="text-from-above"
          >
            Extensive experience of business domains gives our teams a deep
            understanding of business flows and products, as well as ways of
            testing them efficiently.
          </StyledTextTemplate>
        </div>
        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            04 - Transition framework
          </h3>
          <StyledTextTemplate
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base "
            text="p"
            type="text-from-above"
          >
            Test automation is a part of the transition methodology for
            transformation projects to achieve a rapid ramp-up and deliver
            successful automation projects. This approach enables both a quality
            boost and significant cost-savings for our clients.
          </StyledTextTemplate>
        </div>
      </Wrapper>
    </>
  );
}

export default QAContent;
