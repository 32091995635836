import React from "react";

import StyledTextTemplate from "./UI/StyledTextTemplate";
import { WrapperDiv } from "./UI/Wrapper";

export const HeaderMainText = () => {
  return (
    <div className="figcaption mt-[4rem] md:flex md:flex-col md:h-[75vh]  ">
      <WrapperDiv className={" flex flex-col space-y-4"}>
        <div>
          <StyledTextTemplate
            type="text-from-left"
            classes="text-natural-950 text-[38px] leading-[46px] md:text-[48px] md:leading-[58px] font-semibold tracking-wide font-gilroyBold "
            text="h1"
          >
            Development & Design agency
          </StyledTextTemplate>
          <StyledTextTemplate
            type="text-from-left"
            classes="text-natural-950 text-xl  md:text-2xl md:leading-[28px] tracking-[0.01rem] font-gilroy leading-[1.32rem] md:max-w-[50%] "
            text="p"
          >
            We have our solutions, and we offer them to boost your business up.
            Be sure we can find the best solution for you together!
          </StyledTextTemplate>
        </div>
        <StyledTextTemplate
          type="text-from-left"
          classes="text-natural-950 text-center slowdown bg-fuchsia-600 max-w-[12rem] py-3 rounded-md text-xl font-gilroySemibold tracking-wide  hover:bg-fuchsia-500 hover:duration-300  active:duration-300 active:bg-fuchsia-700"
          text="a"
          href="#contacts"
        >
          Get in touch!
        </StyledTextTemplate>
      </WrapperDiv>
    </div>
  );
};

export const HeaderQAText = () => {
  return (
    <div className="figcaption mt-[4rem] md:flex md:flex-col md:h-[75vh] md:justify-center md:items-center">
      <WrapperDiv className={" flex flex-col space-y-4"}>
        <div className="space-y-5">
          <StyledTextTemplate
            text="h1"
            type="text-from-left"
            classes="text-natural-950 text-[38px] leading-[46px] md:text-[48px] md:leading-[58px] font-semibold tracking-wide font-gilroyBold"
          >
            QA Services
          </StyledTextTemplate>

          <div className=" flex flex-col space-y-4">
            <StyledTextTemplate
              type="text-from-left"
              text="p"
              classes="text-natural-950 font-gilroy text-base md:text-lg md:w-[40rem]  "
            >
              Faster time-to-prod, shorter regression cycles, lower cost of
              maintaining quality, greater automation coverage and higher test
              capacity
            </StyledTextTemplate>

            <div className="flex items-center  ">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 f font-gilroy text-base w-full md:text-lg   "
              >
                Cost efficiency. The premier challenge for business leaders
              </StyledTextTemplate>
            </div>

            <div className="flex items-center ">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 font-gilroy text-base w-full md:text-lg  "
              >
                Cost quality. User expectations of product quality are on the up
              </StyledTextTemplate>
            </div>

            <div className="flex items-center ">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 font-gilroy text-base w-full md:text-lg "
              >
                Continuity of delivery, which implies being able to test
                “anywhere, anytime”
              </StyledTextTemplate>
            </div>
          </div>
        </div>
      </WrapperDiv>
    </div>
  );
};

export const HeaderUiUxText = () => {
  return (
    <div className="figcaption mt-[4rem] md:flex md:flex-col md:h-[75vh] md:justify-center md:items-center">
      <WrapperDiv className={" flex flex-col space-y-4"}>
        <div className="space-y-5">
          <StyledTextTemplate
            text="h1"
            type="text-from-left"
            classes="text-natural-950 text-[38px] leading-[46px] md:text-[48px] md:leading-[58px] font-semibold tracking-wide font-gilroyBold"
          >
            UI/UX
          </StyledTextTemplate>

          <div className=" flex flex-col space-y-4">
            <StyledTextTemplate
              type="text-from-left"
              text="p"
              classes="text-natural-950 font-gilroy text-base md:text-lg md:w-[40rem]"
            >
              We provide modern and professional design services for your
              product. Contact us today and learn more about what we can do for
              you!
            </StyledTextTemplate>

            <div className="flex items-center">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 f font-gilroy text-base w-full md:text-lg"
              >
                Design that matches your visual identity
              </StyledTextTemplate>
            </div>

            <div className="flex items-center ">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 font-gilroy text-base w-full md:text-lg"
              >
                Latest tools and technologies
              </StyledTextTemplate>
            </div>

            <div className="flex items-center ">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 font-gilroy text-base w-full md:text-lg"
              >
                Always up to date on the latest design trends
              </StyledTextTemplate>
            </div>

            <div className="flex items-center">
              <StyledTextTemplate
                type="text-from-left"
                text="div"
                classes="min-h-[1rem] min-w-[1rem] bg-fuchsia-600 rounded-full mx-3"
              />
              <StyledTextTemplate
                type="text-from-left"
                text="p"
                classes="text-natural-950 font-gilroy text-base w-full md:text-lg"
              >
                Talented designers with attention to detail
              </StyledTextTemplate>
            </div>
          </div>
        </div>
      </WrapperDiv>
    </div>
  );
};
