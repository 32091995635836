import React from "react";

import Arrow from "../../images/Vector.svg";
import { Link } from "react-router-dom";
import StyledTextTemplate from "./StyledTextTemplate";

function Slide(props) {
  return (
    <article className="rounded-lg bg-neutral-700 pb-5 lg:min-h-[40rem] ">
      <div className="flex justify-center items-center px-[30%] pt-[5%]  ">
        <img
          className={` ${props.classNameImg}  h-[9rem] sm:h-[12rem] md:h-[14rem] `}
          src={props.Img}
          alt=""
        />
      </div>

      <h3 className="text-natural-950 text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] font-gilroySemibold px-5 mb-4 mt-5">
        {props.h}
      </h3>

      <div className="px-5 flex flex-col justify-between lg:h-[22rem] xl:h-[18rem]">
        <div className="flex flex-col space-y-2">
          <StyledTextTemplate
            type={"text-from-above"}
            classes={"text-natural-950 font-gilroy text-base md:text-lg "}
            text={"p"}
          >
            {props.p1}
          </StyledTextTemplate>
          <StyledTextTemplate
            type={"text-from-above"}
            classes={"text-natural-950 font-gilroy text-base md:text-lg "}
            text={"p"}
          >
            {props.p2}
          </StyledTextTemplate>
        </div>

        <div className="flex mediaq justify-between  md:justify-center md:relative mt-[2rem] lg:mt-0">
          <a
            href="#contacts"
            className="text-natural-950 bg-fuchsia-600 w-[46%] py-3 rounded-md text-xl font-gilroySemibold tracking-wide  hover:bg-fuchsia-500 hover:duration-300  active:duration-300 active:bg-fuchsia-700 text-center  "
          >
            Get in Touch
          </a>

          <div className="flex items-center space-x-2 pr-2 md:absolute md:right-0 md:top-3">
            <Link
              className="text-fuchsia-600 font-gilroySemibold text-xl"
              to={props.link}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Learn More
            </Link>
            <img className="w-4 h-3" src={Arrow} alt="" />
          </div>
        </div>
      </div>
    </article>
  );
}

export default Slide;
