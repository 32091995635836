import Footer from "../components/Footer";
import UxContent from "../components/UxContent";

import ArrowUp from "../components/UI/ArrowUp";
import Header from "../components/Header";
import { Helmet } from "react-helmet";

import backgroundImage from "../images/ui-ux.jpg";
import { HeaderUiUxText } from "../components/HeaderText";

const bgImg = "background-uiux";
const text = <HeaderUiUxText />;

export const navBarLinks = [
  { isFirst: true, href: "#solutions", name: "Solutions", link: "solutions" },
  { href: "#process", name: "The process" },
  { isContacts: true, href: "#contacts", name: "Contacts" },
];

function UiUx() {
  return (
    <>
      <Helmet>
        <title>
          Expert UI/UX Design Services - Digital Business Development
        </title>

        <meta
          name="description"
          content="Elevate your digital product with our top-notch UI/UX design services. We deliver user-centered designs that drive business success. Contact us now."
        ></meta>

        <meta name="site_name" content="Digital Business Development" />

        <meta property="og:site_name" content="Digital Business Development" />
        <meta
          property="og:title"
          content="Expert UI/UX Design Services - Digital Business Development"
        />
        <meta
          property="og:description"
          content="Elevate your digital product with our top-notch UI/UX design services. We deliver user-centered designs that drive business success. Contact us now."
        />
        <meta property="og:url" content="https://dbd.am/Ui-Ux" />
        <meta property="og:type" content="website" />

        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/web-mobile-design_caheav.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/web-mobile-design_caheav.jpg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
      </Helmet>
      <ArrowUp />
      <Header
        bgImg={backgroundImage}
        bgImgCSS={bgImg}
        text={text}
        navBarLinks={navBarLinks}
      />
      <UxContent />
      <Footer navBarLinks={navBarLinks} />
    </>
  );
}

export default UiUx;
