import React from "react";
import Creative from "../images/star.svg";
import BestSolutions from "../images/spiral.svg";
import Speed from "../images/steps.svg";
import BestQuality from "../images/goblet.svg";
import StyledTextTemplate from "../UI/StyledTextTemplate";
import Wrapper from "./Wrapper";

const articles = [
  {
    header: `Creative`,
    text: `We believe that creativity is a process where knowledge,
  imagination, and action are put into practice to develop unique
  product.`,
    img: Creative,
    bgImg: "glass-effect1",
  },
  {
    header: `Best Solutions`,
    text: `DBD experts can help you find the best suitable technology
  solutions for your business.`,
    img: BestSolutions,
    bgImg: "glass-effect2",
  },
  {
    header: `Speed`,
    text: `  Through using automation, utilizing an MSP, cross training, and
  more we can offer the best version of the product in a short
  time.`,
    img: Speed,
    bgImg: "glass-effect3",
  },
  {
    header: `Best Quality`,
    text: ` We focus on solving a problem or meeting a need for our product
  to deliver value and high level of quality.`,
    img: BestQuality,
    bgImg: "glass-effect4",
  },
];

function WhyChooseUs() {
  return (
    <>
      <div className="lg:mb-[6rem] mb-[5rem] " id="whychooseus"></div>
      <Wrapper className={"flex flex-col space-y-6 "}>
        <StyledTextTemplate
          type="text-header"
          text="h2"
          classes=" text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-10 font-gilroyBold tracking-wide  lg:text-[32px] lg:leading-[39px]"
        >
          Why choose us?
        </StyledTextTemplate>

        <div className="flex flex-col space-y-6 xl:flex-row xl:grid-cols-4 xl:gap-10 md:grid md:grid-cols-2 md:gap-10 md:space-x-0 md:space-y-0  xl:grid">
          {articles.map((article) => {
            return (
              <article className="rounded-lg bg-neutral-700 flex justify-center items-center space-x-4  md:flex-col md:space-x-0 md:justify-start md:py-8 md:space-y-6 md:px-0 p-2">
                <div
                  className={`${article.bgImg} bg-cover bg-center md:h-[10rem]
md:w-[10rem] min-h-[7rem] min-w-[7rem] flex justify-center items-center`}
                >
                  <img
                    src={article.img}
                    className="md:h-[5rem] md:w-[5rem] h-[4rem] w-[4rem] "
                    alt=""
                  />
                </div>
                <div className="flex flex-col space-y-3 w-[80%] md:items-center">
                  <h3 className="text-natural-950 font-gilroySemibold tracking-wide text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] md:text-center  ">
                    {article.header}
                  </h3>
                  <StyledTextTemplate
                    type="text-from-above"
                    text="p"
                    classes="text-natural-950 font-gilroy text-base md:text-center leading-tight md:text-lg  "
                  >
                    {article.text}
                  </StyledTextTemplate>
                </div>
              </article>
            );
          })}
        </div>
      </Wrapper>
    </>
  );
}

export default WhyChooseUs;
