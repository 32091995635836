import React from "react";

import { IoIosArrowUp } from "react-icons/io";
import { useAllContext } from "./AllContext";

function ArrowUp(props) {
  const {
    isScrolledEnoughDown: isScrolledEnoughDown,
    isScrolledEnoughUp: isScrolledEnoughUp,
  } = useAllContext();

  const style =
    " text-fuchsia-600 text-3xl flex  flex-col space-y-[-16px] items-center justify-center hover:bg-neutral-700  rounded-md z-50 fixed right-[2%] bottom-[10rem] px-5  transition-all duration-[0.5s]   arr ";

  const goToStartOfThePage = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      onClick={goToStartOfThePage}
      className={
        isScrolledEnoughDown && isScrolledEnoughUp
          ? `${style} `
          : `${style} opacity-0 `
      }
    >
      <div
        className={
          isScrolledEnoughDown && isScrolledEnoughUp ? `arrow-animation-1 ` : ``
        }
      >
        <IoIosArrowUp />
      </div>

      <div>
        <IoIosArrowUp
          className={
            isScrolledEnoughDown && isScrolledEnoughUp
              ? `arrow-animation-2 `
              : ``
          }
        />
      </div>
      <div>
        <IoIosArrowUp
          className={
            isScrolledEnoughDown && isScrolledEnoughUp
              ? `arrow-animation-3 `
              : ``
          }
        />
      </div>
    </div>
  );
}

export default ArrowUp;
