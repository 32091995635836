import React, { useState, useEffect } from "react";

import StyledTextTemplate from "../UI/StyledTextTemplate";
import { Link } from "react-router-dom";
import Wrapper from "./Wrapper";
import Slide from "./Slide.jsx";
import UIImg from "../../images/slider/ux-img.svg";
import QAIMG from "../../images/slider/software-development.svg";

const imagesData = [
  {
    slide: (
      <Slide
        Img={UIImg}
        h={"UI/UX Design"}
        p1={
          "the ultimate solution for modern businesses seeking to create user-friendly and visually appealing software."
        }
        p2={
          "Our team of expert designers and user experience professionals work together to ensure that your software is not only functional but also engaging and easy to use."
        }
        link={"/Ui-Ux"}
        classNameImg={""}
      />
    ),
  },
  {
    slide: (
      <Slide
        Img={QAIMG}
        h={"QA Services"}
        p1={`In today's competitive business environment, software quality
          assurance has never been more critical.`}
        p2={` At our IT company, we understand the challenges that businesses face
          in ensuring that their software is robust, reliable, and error-free.
          That's why we offer a comprehensive suite of software testing
          services, including both manual and automation testing.`}
        link={"/QA"}
        classNameImg={""}
      />
    ),
  },
];

function ImageSlider() {
  const [width, setWidth] = useState(window.innerWidth);
  const [allPages, setAllPages] = useState(imagesData.length);
  const [mediaQuery, setMediaQuery] = useState(true);
  const [arrowCurrent, setArrowCurrent] = useState(0);

  const allPagesBigScreen = Math.round(imagesData.length / 2);
  const allPagesSmallScreen = imagesData.length;
  const [currentPagesBigScreen, setCurrentPagesBigScreen] = useState(0);
  const [currentPagesSmallScreen, setCurrentPagesSmallScreen] = useState(true);

  const [imageLeft, setImageLeft] = useState(0);
  const [imageRight, setImageRight] = useState(1);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    setMediaQuery(window.matchMedia("(min-width: 1024px)").matches);

    if (mediaQuery) {
      setAllPages(allPagesBigScreen);

      if ((current + 1) % 2 == 0) {
        setImageLeft(current - 1);
        setImageRight(current);

        setCurrentPagesBigScreen(Math.round((current + 1) / 2));
        setArrowCurrent(Math.round((current + 1) / 2));
      } else {
        setImageLeft(current);
        setImageRight(current + 1);

        setCurrentPagesBigScreen(Math.round((current + 2) / 2));
        setArrowCurrent(Math.round((current + 2) / 2));
      }
    } else {
      setAllPages(allPagesSmallScreen);
      setArrowCurrent(current + 1);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const grey = "fill-[#a8a8a8] pointer-events-none";

  const nextSlide = () => {
    if (mediaQuery) {
      if ((current + 1) % 2 == 0) {
        setCurrent(
          current + 1 === allPagesSmallScreen
            ? allPagesSmallScreen - 1
            : current + 2
        );
      } else {
        setCurrent(
          current + 1 === allPagesSmallScreen - 1
            ? allPagesSmallScreen - 2
            : current + 2
        );
      }
    } else {
      setCurrent(current === allPages - 1 ? allPages - 1 : current + 1);
    }
  };

  const prevSlide = () => {
    if (mediaQuery) {
      if ((current + 1) % 2 == 0) {
        setCurrent(current + 1 === 2 ? 1 : current - 2);
      } else {
        setCurrent(current + 1 === 1 ? 0 : current - 2);
      }
    } else {
      setCurrent(current === 0 ? 0 : current - 1);
    }
  };

  if (!Array.isArray(imagesData) || allPages <= 0) {
    return null;
  }

  return (
    <>
      <StyledTextTemplate
        text="h2"
        type="text-header"
        classes="  text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-[5rem] font-gilroyBold tracking-wide   md:text-[32px] md:leading-[39px] mb-10 "
      >
        Our Services
      </StyledTextTemplate>
      <Wrapper
        className={"flex w-[94%] m-auto md:w-[80%] justify-between gap-10"}
      >
        {imagesData.map((slide, index) => {
          if (mediaQuery) {
            return (
              <div
                className={
                  index === currentPagesBigScreen ||
                  index === currentPagesBigScreen - 1
                    ? " grow  lg:max-w-[48%] "
                    : "hidden"
                }
                key={index}
              >
                {slide.slide}
              </div>
            );
          } else {
            return (
              <div
                className={
                  index === current ? "grow  lg:max-w-[48%] " : "hidden"
                }
                key={index}
              >
                {slide.slide}
              </div>
            );
          }
        })}
      </Wrapper>
      <div
        id="why"
        className="flex justify-center items-center space-x-10 mt-8 lg:hidden"
      >
        <button className="prev group" onClick={prevSlide}>
          <svg
            className={`transition-all duration-300 ${
              arrowCurrent === 1
                ? grey
                : "fill-fuchsia-600 active:translate-y-0.5  active:fill-fuchsia-800"
            }  `}
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.718869 13.5375L12.7194 23.5379C13.1273 23.8753 13.6524 24.0374 14.1796 23.9885C14.7067 23.9397 15.1931 23.6839 15.532 23.2772C15.871 22.8705 16.0351 22.3461 15.9882 21.8187C15.9413 21.2914 15.6874 20.8041 15.282 20.4636L5.1253 12.0002L15.282 3.5369C15.6889 3.19685 15.9443 2.70918 15.9919 2.18099C16.0395 1.65281 15.8756 1.12732 15.536 0.719948C15.1965 0.31258 14.7091 0.056652 14.181 0.00838645C13.6528 -0.0398791 13.1271 0.123462 12.7194 0.462532L0.718869 10.4631C0.493851 10.6511 0.312848 10.8862 0.188646 11.1518C0.0644437 11.4174 7.06926e-05 11.7071 7.0667e-05 12.0003C7.06414e-05 12.2935 0.0644436 12.5831 0.188646 12.8488C0.312848 13.1144 0.493851 13.3495 0.718869 13.5375Z" />
          </svg>
        </button>
        <div className="text-natural-950 text-2xl font-rokkitt flex items-start gap-[0.1rem]">
          <span className="slide-number">
            {mediaQuery ? currentPagesBigScreen : current + 1}
          </span>
          /<span className="text-lg total-number">{allPages}</span>
        </div>
        <button className="next group" onClick={nextSlide}>
          <svg
            className={`transition-all duration-300 ${
              arrowCurrent === allPages
                ? grey
                : "fill-fuchsia-600 active:translate-y-0.5  active:fill-fuchsia-800"
            }  `}
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.2812 10.4625L3.28072 0.462102C2.87277 0.124652 2.34771 -0.037385 1.82054 0.0114757C1.29336 0.0603365 0.807031 0.316113 0.468044 0.722793C0.129057 1.12947 -0.0349586 1.65391 0.0119133 2.18127C0.0587852 2.70863 0.312725 3.19592 0.718124 3.53644L10.8748 11.9998L0.718124 20.4631C0.311163 20.8031 0.0558196 21.2908 0.00818571 21.819C-0.0394481 22.3472 0.124523 22.8727 0.46408 23.2801C0.803636 23.6874 1.29101 23.9433 1.81913 23.9916C2.34726 24.0399 2.87295 23.8765 3.28072 23.5375L15.2812 13.5369C15.5062 13.3489 15.6872 13.1138 15.8114 12.8482C15.9356 12.5826 16 12.2929 16 11.9997C16 11.7065 15.9356 11.4169 15.8114 11.1512C15.6872 10.8856 15.5062 10.6505 15.2812 10.4625Z" />
          </svg>
        </button>
      </div>
    </>
  );
}

export default ImageSlider;
