import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import StyledTextTemplate from "./StyledTextTemplate";
import Wrapper from "./Wrapper";

function GotQ() {
  const [enteredName, setEnteredName] = useState("");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitText, setSubmitText] = useState("Contact us");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredText, setEnteredText] = useState("");
  const [correctEmail, setCorrectEmail] = useState("");
  const [correctName, setCorrectName] = useState("");
  const [correctText, setCorrectText] = useState("");
  const [isEmailCorrectionActive, setIsEmailCorrectionActive] = useState(false);
  const [isNameCorrectionActive, setIsNameCorrectionActive] = useState(false);
  const [isTextCorrectionActive, setIsTextCorrectionActive] = useState(false);
  const [emailTestIsOn, setEmailTestIsOn] = useState(false);

  const inputRef = useRef(null);

  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const textChangeHandler = (event) => {
    setEnteredText(event.target.value);
  };

  useEffect(() => {
    if (enteredEmail.trim() === "") {
      setCorrectEmail("Your email field is empty");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(enteredEmail) &&
      emailTestIsOn
    ) {
      setCorrectEmail("Please, correct your email");
      setIsEmailCorrectionActive(true);
    } else if (emailTestIsOn) {
      setIsEmailCorrectionActive(false);
      setEmailTestIsOn(false);
    } else {
      setIsEmailCorrectionActive(false);
    }
  }, [enteredEmail]);

  useEffect(() => {
    if (enteredName.trim() === "") {
      setCorrectName("Your name field is empty");
    } else if (enteredName.length >= 60) {
      setCorrectName("Your name should contain no more than 60 characters");
      setIsNameCorrectionActive(true);
    } else {
      setIsNameCorrectionActive(false);
    }
  }, [enteredName]);

  useEffect(() => {
    if (enteredText.trim() === "") {
      setCorrectText("Your text field is empty");
    } else if (enteredText.trim().length < 15) {
      setCorrectText("Your text should be at least 15 characters long");
    } else if (enteredText.length >= 300) {
      setCorrectText("Your text should contain less than 300 characters");
      setIsTextCorrectionActive(true);
    } else {
      setIsTextCorrectionActive(false);
    }
  }, [enteredText]);

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      event.preventDefault();

      let isNameOk = false;
      let isTextOk = false;
      let isEmailOk = false;

      if (enteredName.trim() === "") {
        setIsNameCorrectionActive(true);
      } else if (enteredName.trim().length >= 60) {
        setIsNameCorrectionActive(true);
      } else {
        isNameOk = true;
      }

      if (enteredText.trim() === "") {
        setIsTextCorrectionActive(true);
      } else if (enteredText.trim().length < 15) {
        setIsTextCorrectionActive(true);
      } else if (enteredText.trim().length > 300) {
        setIsTextCorrectionActive(true);
      } else {
        isTextOk = true;
      }

      if (enteredEmail.trim() === "") {
        setIsEmailCorrectionActive(true);
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(enteredEmail)
      ) {
        isEmailOk = true;
      } else {
        setCorrectEmail("Please, correct your email");
        setIsEmailCorrectionActive(true);
        setEmailTestIsOn(true);
      }

      if (isEmailOk && isNameOk && isTextOk) {
        const result = await axios.post("https://back.dbd.am/contact", {
          email: `${enteredEmail}`,

          message: `${enteredText}`,
          site: "DBD",

          name: `${enteredName}`,
        });

        if (result.status === 200) {
          setIsSubmitError(false);
          setSubmitText("Thank you!");
          setIsSubmitted(true);

          function AfterEffect() {
            setTimeout(() => {
              setIsSubmitted(false);
              setSubmitText("Contact us");
              setEnteredName("");
              setEnteredText("");
              setEnteredEmail("");
              inputRef.current.blur();
            }, 2000);
          }

          AfterEffect();
        } else {
          setSubmitText("Something went wrong ;( Try again later");
        }
      }
    } catch (error) {
      setIsSubmitError(true);
      setSubmitText("Something went wrong ;( Try again later");
    }
  };

  return (
    <>
      <div id="contacts" className="pt-[6rem] lg:pt-[7rem]"></div>
      <section className="w-[94%] m-auto flex flex-col space-y-6 mb-12 md:w-[70%]">
        <StyledTextTemplate
          type="text-header"
          text="h2"
          classes="text-center text-natural-950 text-[28px] mb-6 font-semibold  font-gilroyBold tracking-wide leading-[34px] md:text-[32px] md:[39px] opacity-0  transparent transition-all duration-[2s] "
        >
          Got questions?
        </StyledTextTemplate>

        <form onSubmit={submitHandler} className="flex flex-col space-y-5">
          <div className="flex flex-col space-y-5 md:flex-row md:justify-between  md:space-y-0 md:space-x-8">
            <div className="flex flex-col space-y-2 w-full">
              <label
                htmlFor="name"
                className="font-gilroyMedium text-natural-950 text-lg"
              >
                Name<span className="text-red-600 name-asterisk">*</span>
              </label>

              <input
                ref={inputRef}
                id="name"
                name="name"
                type="text"
                maxLength="60"
                className={
                  isNameCorrectionActive
                    ? "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-red-600 border-red-600 rounded-lg w-full bg-neutral-800 "
                    : "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-fuchsia-600 border-natural-950 rounded-lg w-full bg-neutral-800 "
                }
                placeholder="Type your name..."
                value={enteredName}
                onChange={nameChangeHandler}
              />
              <div
                className={
                  isNameCorrectionActive
                    ? "invalid-email font-gilroySemibold text-red-600"
                    : "invalid-email font-gilroySemibold text-red-600 hidden"
                }
              >
                {correctName}
              </div>
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <label
                htmlFor="name"
                className="font-gilroyMedium text-natural-950 text-lg"
              >
                E-mail<span className="text-red-600 email-asterisk">*</span>
              </label>

              <input
                ref={inputRef}
                id="email"
                name="email"
                type="text"
                maxLength="50"
                className={
                  isEmailCorrectionActive
                    ? "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-red-600 border-red-600 rounded-lg w-full bg-neutral-800 "
                    : "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-fuchsia-600 border-natural-950 rounded-lg w-full bg-neutral-800 "
                }
                placeholder="example@example.com"
                value={enteredEmail}
                onChange={emailChangeHandler}
              />
              <div
                className={
                  isEmailCorrectionActive
                    ? "invalid-email font-gilroySemibold text-red-600"
                    : "invalid-email font-gilroySemibold text-red-600 hidden"
                }
              >
                {correctEmail}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label
              htmlFor="name"
              className="font-gilroyMedium text-natural-950 text-lg"
            >
              Your message
              <span className="text-red-600 message-asterisk">*</span>
            </label>

            <textarea
              re
              id="text"
              name="text"
              type="text"
              maxLength="300"
              ref={inputRef}
              className={
                isTextCorrectionActive
                  ? "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-red-600 border-red-600 rounded-lg w-full bg-neutral-800 min-h-[4rem] max-h-[20rem]"
                  : "font-gilroy text-base text-natural-950 p-3  border-[1px] focus:border-fuchsia-600 border-natural-950 rounded-lg w-full bg-neutral-800 min-h-[4rem] max-h-[20rem]"
              }
              placeholder="Type your message or questions..."
              value={enteredText}
              onChange={textChangeHandler}
            ></textarea>
            <div
              className={
                isTextCorrectionActive
                  ? "invalid-email font-gilroySemibold text-red-600"
                  : "invalid-email font-gilroySemibold text-red-600 hidden"
              }
            >
              {correctText}
            </div>
          </div>

          <div className="w-full pt-[3.5rem] flex justify-center items-center">
            <button
              id="btn"
              className={` ${isSubmitted ? "active-submit" : ""}   ${
                isSubmitError ? "bg-red-600" : "bg-fuchsia-600"
              }  py-3 button-submit transition-all duration-[1s] w-full md:w-[50%] hover:bg-fuchsia-500 hover:duration-300  active:duration-300 active:bg-fuchsia-700 `}
              type="submit"
            >
              <p
                id="btnText "
                className="transition-all duration-[1s] text-natural-950 text-xl font-gilroySemibold tracking-wide"
              >
                {submitText}
              </p>
              <div className="check-box transition-all duration-[1s] ">
                <svg
                  className="submit-svg transition-all duration-[1s] absolute top-[-1.25rem] left-[-1.2rem]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                >
                  <path
                    fill="transparent"
                    className="transition-all duration-[2s]"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </button>
          </div>
        </form>
      </section>
    </>
  );
}

export default GotQ;
