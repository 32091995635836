import React from "react";
import "../../index.css";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAllContext } from "./AllContext";

function NavBar(props) {
  const {
    isHamburgerButtonPressed: isHamburgerButtonPressed,
    toggleHamburgerButton: toggleHamburgerButton,
    closeHamburgerButton: closeHamburgerButton,
  } = useAllContext();

  const [hidden, setHidden] = useState("");
  const [display, setDisplay] = useState(false);

  const links = props.navBarLinks;

  useEffect(() => {
    if (isHamburgerButtonPressed === false) {
      setDisplay(false);
      function AfterEffect() {
        setTimeout(() => {
          setHidden("hidden");
        }, 1000);
      }

      AfterEffect();
    } else if (isHamburgerButtonPressed === true) {
      setHidden("");

      function AfterEffect() {
        setTimeout(() => {
          setDisplay(true);
        }, 100);
      }

      AfterEffect();
    }
  }, [isHamburgerButtonPressed]);

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const openDropdownMenu = () => {
    if (window.innerWidth > 1024) {
      setIsDropdownActive(true);
    }
  };

  const closeDropdownMenu = () => {
    if (window.innerWidth > 1024) {
      setIsDropdownActive(false);
    }
  };

  const openDropdownMenuClick = () => {
    if (window.innerWidth < 1024) {
      if (isDropdownActive === false) {
        setIsDropdownActive(true);
      } else {
        setIsDropdownActive(false);
      }
    }
  };

  return (
    <nav
      className={`
        ${display ? "left-[0vw] " : `left-[100vw] ${hidden} `}
        text-natural-950 lg:space-x-12  lg:items-center lg:flex lg:static lg:flex-row lg:h-auto lg:bg-transparent lg:p-0 lg:space-y-0 lg:translate-x-0 absolute flex flex-col  md:top-[3.6rem] top-[3.4rem]  right-0 h-screen z-50 bg-neutral-800 space-y-8 pt-16 pl-10 transition-all duration-300
        ease-in-out `}
    >
      {links?.map((link, index) => {
        if (link?.isFirst === true) {
          return (
            <a
              className=" hover:text-fuchsia-600  font-gilroySemibold text-xl text-natural-950 whitespace-nowrap "
              href={link.href}
              onClick={closeHamburgerButton}
              key={index}
            >
              {link.name}
            </a>
          );
        } else {
          return null;
        }
      })}

      <div
        className="flex py-2 relative"
        onMouseEnter={openDropdownMenu}
        onMouseLeave={closeDropdownMenu}
        onClick={openDropdownMenuClick}
      >
        <button
          className={`   flex space-x-2 justify-between services-menu  font-gilroySemibold text-xl text-natural-950 relative`}
        >
          <span className={`${isDropdownActive ? "text-fuchsia-600" : ""}`}>
            Services
          </span>
          <div className="services">
            <span
              className={
                isDropdownActive
                  ? ` bar1 block w-[10px] rounded-lg h-[2px] my-[4.5px] mx-auto transition-transform duration-300 ease-in-out bg-fuchsia-600 
                  translate-y-[0.50rem] -translate-x-[0.35rem] -rotate-45`
                  : `bar1 block w-[10px] rounded-lg h-[2px] my-[4.5px] mx-auto transition-transform duration-300 ease-in-out bg-natural-950 
                  translate-y-[0.50rem] -translate-x-[0.35rem] rotate-45`
              }
            ></span>

            <span
              className={
                isDropdownActive
                  ? `bar2 block w-[10px] h-[2px] rounded-lg  my-[0px] mx-auto transition-transform duration-300 ease-in-out bg-fuchsia-600  translate-y-[0.1rem] translate-x-[0.01rem] rotate-45`
                  : `bar2 block w-[10px] h-[2px] rounded-lg  my-[0px] mx-auto transition-transform duration-300 ease-in-out bg-natural-950  translate-y-[0.1rem] translate-x-[0.01rem] -rotate-45`
              }
            ></span>
          </div>
        </button>{" "}
      </div>
      <div
        onMouseEnter={openDropdownMenu}
        onMouseLeave={closeDropdownMenu}
        className={`
          ${isDropdownActive ? "active" : ""}
            
              services-dropdown-menu services-menu top-[2.5rem] left-[10%] lg:absolute hidden w-[13rem]  lg:flex bg-neutral-800 px-8 lg:py-6 rounded-lg  scale-0 ease-in text-natural-950 text-lg font-gilroySemibold  flex-col space-y-5 `}
      >
        <Link
          className=" hover:text-fuchsia-600 "
          to="/Ui-Ux"
          onClick={() => {
            closeHamburgerButton();
            window.scroll(0, 0);
          }}
        >
          UI/UX
        </Link>

        <Link
          className=" hover:text-fuchsia-600 "
          to="/QA"
          onClick={() => {
            closeHamburgerButton();
            window.scroll(0, 0);
          }}
        >
          QA Services
        </Link>
      </div>

      {links?.map((link, index) => {
        if (link?.isFirst === true) {
          return null;
        } else {
          return (
            <a
              className=" hover:text-fuchsia-600  font-gilroySemibold text-xl text-natural-950 whitespace-nowrap "
              href={link.href}
              onClick={closeHamburgerButton}
              key={index}
            >
              {link.name}
            </a>
          );
        }
      })}
    </nav>
  );
}

export default NavBar;
