import React from "react";
import Img from "../images/2d-screen-img.jpg";
import StyledTextTemplate from "./StyledTextTemplate";
import Wrapper from "./Wrapper";

function AboutUs() {
  const handle = true;

  return (
    <>
      <Wrapper>
        <StyledTextTemplate
          type="text-header"
          classes=" text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-10 font-gilroyBold tracking-wide  lg:text-[32px] lg:leading-[39px]"
          text="h2"
        >
          About Us
        </StyledTextTemplate>

        <article className="rounded-lg bg-neutral-700 pb-5 lg:bg-transparent lg:flex lg:justify-between">
          <img
            className="lg:grow lg:max-w-[48%] rounded-t-lg lg:rounded-lg object-cover"
            src={Img}
            alt=""
          />

          <div className="lg:grow lg:max-w-[48%] lg:my-[3%] lg:ml-[8%] lg:mr-0 m-[1.5rem]">
            <StyledTextTemplate
              type="text-from-right"
              classes="text-natural-950  text-[22px] leading-[26px]  lg:text-[26px] lg:leading-[31px] font-gilroySemibold  m-auto mb-4 mt-5"
              text="p"
            >
              Who we are & What we do?
            </StyledTextTemplate>

            <div className="m-auto flex flex-col space-y-2">
              <StyledTextTemplate
                type="text-from-right"
                classes="text-natural-950 font-gilroy text-base lg:text-lg"
                text="p"
              >
                DBD provides a comprehensive and remotely managed IT department
                for small and medium-sized businesses. Our team of experts is
                dedicated to offering a diverse range of solutions that cater to
                your business needs. Our services are cost-effective, saving you
                the expenses of hiring an in-house IT team.
              </StyledTextTemplate>
              <div className="flex">
                <StyledTextTemplate
                  text="div"
                  type="text-from-right"
                  classes="h-[0.80rem] w-[0.80rem] bg-fuchsia-600 rounded-[2px] mx-3 mt-1"
                />
                <StyledTextTemplate
                  type="text-from-right"
                  classes="text-natural-950 f font-gilroy text-base w-full lg:text-lg"
                  text="p"
                >
                  Our process commences with a detailed analysis of your
                  requirements, enabling us to provide a customized solution
                  proposal that aligns with your business goals.
                </StyledTextTemplate>
              </div>
              <div className="flex">
                <StyledTextTemplate
                  text="div"
                  type="text-from-right"
                  classes="h-[0.80rem] w-[0.80rem] bg-fuchsia-600 rounded-[2px] mx-3 mt-1  "
                />
                <StyledTextTemplate
                  type="text-from-right"
                  classes="text-natural-950 font-gilroy text-base w-full lg:text-lg"
                  text="p"
                >
                  Our team takes a meticulous approach to designing and
                  developing your product, ensuring efficient management and
                  support.
                </StyledTextTemplate>
              </div>

              <StyledTextTemplate
                type="text-from-right"
                classes="text-natural-950 font-gilroy text-base  lg:text-lg"
                text="p"
              >
                We work closely with you to bring your vision to fruition,
                delivering a product that meets your expectations and
                requirements.
              </StyledTextTemplate>
            </div>
          </div>
        </article>
      </Wrapper>
    </>
  );
}

export default AboutUs;
