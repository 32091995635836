import React from "react";
import UxIcon1 from "./images/uxIcon1.svg";
import UxIcon2 from "./images/uxIcon2.svg";
import UxIcon3 from "./images/uxIcon3.svg";
import UxIcon4 from "./images/uxIcon4.svg";
import UxIcon5 from "./images/uxIcon5.svg";
import UxIcon6 from "./images/uxIcon6.svg";
import UxChart from "./images/chart.svg";
import StyledTextTemplate from "./UI/StyledTextTemplate";
import Wrapper from "./UI/Wrapper";

function UxContent() {
  return (
    <>
      <Wrapper>
        <StyledTextTemplate
          text="h2"
          type="text-header"
          classes="text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-12 font-gilroyBold tracking-wide  md:text-[32px] md:leading-[39px] "
        >
          Our Solutions
        </StyledTextTemplate>
        <ul className=" pb-5 flex flex-col items-center xl:grid xl:grid-cols-3 md:gap-20 xl:justify-start uxdisplay space-y-20 sm:space-y-0 ">
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={UxIcon1} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Design thinking
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Creation a good process with all team members to understand all
              needs our users have.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={UxIcon2} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Strategy & Targeting
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Making a general plan to achieve one or more goals under
              conditions of uncertainty, and target users.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={UxIcon3} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Research
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              This process is made so that the requirements and problems
              obtained meet the target market.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start">
            <div>
              <img src={UxIcon4} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              UI Process
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              Ensure taht all guides contained in the UI are usable and have
              been chosen right.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={UxIcon5} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Prototypes
            </h3>

            <p className="text-natural-950 font-gilroy text-base md:text-lg text-center ">
              A prototype is an early sample, model, or release of a product
              built to test a concept or process.
            </p>
          </li>
          <li className="flex flex-col justify-center items-center space-y-5 md:justify-start ">
            <div>
              <img src={UxIcon6} alt="" />
            </div>

            <h3 className="text-natural-950 text-[22px] leading-[26px]  md:text-[26px] md:leading-[31px] font-gilroySemibold">
              Testing
            </h3>

            <p
              id="process"
              className="text-natural-950 font-gilroy text-base md:text-lg text-center "
            >
              This is using good testing methods, so that the App, or Web
              product can be well received by the user.
            </p>
          </li>
        </ul>
      </Wrapper>
      <Wrapper>
        <StyledTextTemplate
          type="text-header"
          text="h2"
          classes="text-center text-natural-950 text-[28px] leading-[34px] font-semibold mt-16 mb-12 font-gilroyBold tracking-wide   md:text-[32px] md:leading-[39px] "
        >
          The Whole Process
        </StyledTextTemplate>

        <div className="flex flex-row md:flex-col ">
          <div className="flex flex-col   pt-[0.2rem] md:flex-row md:items-center">
            <div className="flex flex-col  items-center grow space-y-2 md:space-y-0 md:flex-row ">
              <div className=" m-4 p-3 rounded-full bg-fuchsia-600 md:ml-0"></div>

              <div className=" border-r-[0.3rem]  border-[#D6B1FF] border-dotted h-[5.35rem] md:h-0  md:border-t-[0.3rem] md:grow "></div>
            </div>

            <div className="flex flex-col  items-center  grow space-y-2  md:space-y-0 md:flex-row  ">
              <div className=" m-4 p-3 rounded-full bg-fuchsia-600 "></div>

              <div className=" border-r-[0.3rem]  border-[#D6B1FF] border-dotted h-[5.35rem] md:h-0 md:grow md:border-t-[0.3rem] "></div>
            </div>

            <div className="flex flex-col  items-center  grow space-y-2  md:space-y-0 md:flex-row   ">
              <div className=" m-4 p-3 rounded-full bg-fuchsia-600 "></div>

              <div className=" border-r-[0.3rem]  border-[#D6B1FF] border-dotted h-[4rem] md:h-0 md:grow md:border-t-[0.3rem] "></div>
            </div>

            <div className="flex flex-col  items-center  grow md:grow-0 md:mr-[13%] lg:mr-[18%]">
              <div className=" m-4 p-3 rounded-full bg-fuchsia-600 "></div>

              <div className=" border-r-[0.05rem]  border-transparent border-dotted h-[5rem] md:h-0  "></div>
            </div>
          </div>

          <div className="flex flex-col gap-10 md:flex-row ">
            <div className="flex flex-col items-start justify-start">
              <h3 className="text-natural-950 text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] font-gilroySemibold w-[92%]  mb-4 mt-5">
                Ideate
              </h3>

              <StyledTextTemplate
                classes="text-natural-950 font-gilroy text-base md:text-lg "
                type="text-from-above"
                text="p"
              >
                Turn idea from concept and brainstorm to MVP
              </StyledTextTemplate>
            </div>

            <div className="flex flex-col items-start justify-start">
              <h3 className="text-natural-950 text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] font-gilroySemibold w-[92%]  mb-4 mt-5">
                Design
              </h3>

              <StyledTextTemplate
                type="text-from-above"
                text="p"
                classes="text-natural-950 font-gilroy text-base md:text-lg "
              >
                Sketch out the product to align the user needs
              </StyledTextTemplate>
            </div>

            <div className="flex flex-col items-start justify-start">
              <h3 className="text-natural-950 text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] font-gilroySemibold w-[92%]  mb-4 mt-5">
                Develop
              </h3>

              <StyledTextTemplate
                type="text-from-above"
                text="p"
                classes="text-natural-950 font-gilroy text-base md:text-lg "
              >
                Convert the designs into a live application
              </StyledTextTemplate>
            </div>

            <div className="flex flex-col items-start justify-start">
              <h3 className="text-natural-950 text-[22px] leading-[26px] md:text-[26px] md:leading-[31px] font-gilroySemibold w-[92%]  mb-4 mt-5">
                Deploy
              </h3>
              <StyledTextTemplate
                type="text-from-above"
                text="p"
                classes="text-natural-950 font-gilroy text-base md:text-lg "
              >
                Launching the application to the market
              </StyledTextTemplate>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className={`overflow-auto mb-[5rem] `}>
        <div className="w-[60rem] md:w-auto ">
          <img src={UxChart} className="md:h-[33rem] " alt="" />
        </div>
      </Wrapper>
      <Wrapper className="flex flex-col space-y-6  md:grid md:grid-cols-3 md:gap-14 md:space-y-0  ">
        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            01 - Ideation
          </h3>

          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>Brainstorming</li>
            <li>Research</li>
            <li>Interview</li>
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            02 - Research & Wireframe
          </h3>
          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>Interview</li>
            <li>Competitor analysis</li>
            <li>User flow</li>
            <li>User map</li>
            <li>Persona research</li>
            <li>Low/High Fidelity wireframing</li>
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            03 - HiFi Design
          </h3>
          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>Brand identity</li>
            <li>Design system</li>
            <li>Design UI</li>
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            04 - Prototype & Testing (UT)
          </h3>
          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>General prototype</li>
            <li>Usability testing</li>
            <li>Gather feedbacks</li>
            <li>Design revision</li>
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            05 - Development
          </h3>
          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>Handoff to developer</li>
            <li>Assets documentation</li>
            <li>From UI to code</li>
            <li>Unit testing</li>
            <li>Gather feedbacks</li>
            <li>Layout revision after feedback</li>
            <li>Ready to test</li>
          </StyledTextTemplate>
        </div>

        <div>
          <h3 className=" rounded-lg bg-[#585858]  p-2 w-full text-natural-950 font-gilroySemibold text-lg mb-3">
            06 - Testing & Release
          </h3>
          <StyledTextTemplate
            text="ul"
            type="text-from-above"
            classes="flex flex-col  list-disc font-gilroy text-natural-950 text-base pl-5"
          >
            <li>Testcase creation</li>
            <li>Flow creation from QA</li>
            <li>QA documentation</li>
            <li>General testing</li>
            <li>Release MVP</li>
          </StyledTextTemplate>
        </div>
      </Wrapper>
    </>
  );
}

export default UxContent;
