import React from "react";
import GotQ from "./UI/GotQ";
import Bottom from "./UI/Bottom";

function Footer(props) {
  const navBarLinks = props.navBarLinks;
  return (
    <>
      <GotQ />
      <Bottom navBarLinks={navBarLinks} />
    </>
  );
}

export default Footer;
