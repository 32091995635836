import React from "react";
import "../index.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import QAContent from "../components/QAContent";

import ArrowUp from "../components/UI/ArrowUp";

import backgroundImage from "../images/QA1st.jpg";
import { HeaderQAText } from "../components/HeaderText";

import { Helmet } from "react-helmet";

const bgImg = "background-qa";
const text = <HeaderQAText />;

export const navBarLinks = [
  {
    isFirst: true,
    href: "#benefits",
    name: "Benefits of QA",
    link: "benefits",
  },
  { href: "#approach", name: "Our approach" },
  { isContacts: true, href: "#contacts", name: "Contacts" },
];

function QA() {
  return (
    <>
      <Helmet>
        <title>Quality Assurance Services for IT Products | DBD</title>

        <meta
          name="description"
          content="Ensure the quality of your IT product with our comprehensive QA services. Trust the experts at DBD to deliver top-notch results"
        ></meta>

        <meta name="site_name" content="Digital Business Development" />

        <meta property="og:site_name" content="Digital Business Development" />
        <meta
          property="og:title"
          content="Quality Assurance Services for IT Products | DBD"
        />
        <meta
          property="og:description"
          content="Ensure the quality of your IT product with our comprehensive QA services. Trust the experts at DBD to deliver top-notch results."
        />
        <meta property="og:url" content="https://dbd.am/QA" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/qa-testing_ntzcda.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://res.cloudinary.com/dvmfh2aul/image/upload/v1675763812/qa-testing_ntzcda.jpg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
      </Helmet>
      <ArrowUp />
      <Header
        bgImg={backgroundImage}
        bgImgCSS={bgImg}
        text={text}
        navBarLinks={navBarLinks}
      />
      <QAContent />
      <Footer navBarLinks={navBarLinks} />
    </>
  );
}

export default QA;
