import React, { useState, useContext } from "react";

export const AllContext = React.createContext();

export function useAllContext() {
  return useContext(AllContext);
}

export function AllContextProvider({ children }) {
  const [isHamburgerButtonPressed, setIsHamburgerButtonPressed] =
    useState(false);

  const toggleHamburgerButton = () => {
    setIsHamburgerButtonPressed((prevHamburgerState) => !prevHamburgerState);
  };

  const closeHamburgerButton = () => {
    setIsHamburgerButtonPressed(false);
  };

  const [isScrolledEnoughDown, setIsScrolledEnoughDown] = useState(false);

  const [isScrolledEnoughUp, setIsScrolledEnoughUp] = useState(false);

  return (
    <AllContext.Provider
      value={{
        isHamburgerButtonPressed: isHamburgerButtonPressed,
        toggleHamburgerButton: toggleHamburgerButton,
        closeHamburgerButton: closeHamburgerButton,
        isScrolledEnoughDown: isScrolledEnoughDown,
        setIsScrolledEnoughDown: setIsScrolledEnoughDown,
        isScrolledEnoughUp: isScrolledEnoughUp,
        setIsScrolledEnoughUp: setIsScrolledEnoughUp,
      }}
    >
      {children}
    </AllContext.Provider>
  );
}
